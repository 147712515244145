.hide {
  display: none; }
// Home
.page-default {
  background: $grey-color-light;
  padding-top: 0; }

.site-home {
  padding: $spacing-unit 0; }

.home-col-wrapper {
  font-size: 18px;
  color: $grey-color;
  text-align: center;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix; }

.home-col {
  display: block;
  float: left;
  padding: $spacing-unit 0;
  margin-bottom: $spacing-unit / 2;
  margin-left: $spacing-unit / 2;
  border-radius: $spacing-unit / 2;
  width: -webkit-calc(25% - (#{$spacing-unit} / 2));
  width: calc(25% - (#{$spacing-unit} / 2));
  .twa {
    display: inline-block;
    width: 100%;
    margin: 0; }
  .header {
    margin: 0; } }
.home-col:hover {
  background: $background-color;
  text-decoration: none; }



[class^="type-"] {
  @extend .twa; }
.type-link {
  @extend .twa-anchor; }
.type-video {
  @extend .twa-tv; }
.type-cuina {
  @extend .twa-egg; }
.type-movie {
  @extend .twa-movie-camera; }
.type-image {
  @extend .twa-camera; }
.type-quote {
  @extend .twa-speech-balloon; }
.type-link-list {
  @extend .twa-link; }
.type-movie,
.type-movies {
  @extend .twa-vhs; }
.type-article {
  @extend .twa-blue-book; }
.type-misc {
  @extend .twa-honeybee; }

.btn-group-lg [class^="type-"] {
  @extend .twa-4x;
  clear: both;
  display: block;
  margin: 0 auto; }
.btn-group-lg label {
  padding-top: .75rem;
  padding-bottom: .75rem; }
.btn [class^="type-"] {
  filter: grayscale(1); }

.btn-group-lg .btn [class^="type-"],
.btn.active [class^="type-"] {
  filter: grayscale(0) hue-rotate(302deg); }
.btn-group-lg .btn [class^="type-"] {
  filter: blur(2px); }

input[type=checkbox].invisible {
  position: absolute;
  top: -9999px;
  left: -9999px; }
input[type=checkbox].invisible:checked ~ label {
  background: $brand-color; }
