
body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: 300;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizelegibility

}

/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}



/**
 * Lists
 */
dt {
  margin-bottom: 0;
  font-weight: bold;
  font-weight: 400;
}

dd {
  margin-left: $spacing-unit / 2;
  margin-bottom: 0;
  + dd {
    margin-bottom: $spacing-unit / 2;
  }
}

/**
 * Links
 */
a {
  color: $brand-color;
  text-decoration: none;

  &:visited {
    color: darken($brand-color, 15%);
  }

  &:hover {
    color: $text-color;
    text-decoration: underline;
  }
}

/**
 * Code formatting
 */
pre,
code {
  // border: 1px solid $grey-color-light;
  border-radius: 3px;
  // background-color: #eef;
  font-family: 'Source Code Pro', monospace;
}

code {
  font-size: .9em;
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  // overflow-x: scroll;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit;
  padding-left: $spacing-unit;
  @extend %clearfix;
}



/**
 * Clearfix
 */
%clearfix {

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}


/**
 * Icons
 */
.icon {

  > svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;

    path {
      fill: $grey-color;
    }
  }
}

.label {
  font-weight: 300;
}
