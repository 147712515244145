#timeline{
  margin:30px 0 30px
}

#timeline div{
  position:relative
}

#timeline div:after{
  content:'';
  width:2px;
  position:absolute;
  top:10px;
  bottom:10px;
  left:56px;
  z-index:1;
  background: $grey-color-light
}

#timeline .year{
  position:relative
}

#timeline .year--header{
  position:absolute;
  color:$grey-color-dark;
  margin: -8px 0 0;
  font-size:1.4em;
  line-height:1.5em
}

@media screen and (max-width:479px){
  #timeline .year--header{
    font-size:1.1em
  }

}
#timeline .year .year--container{
  padding-bottom:2.25em;
}
#timeline .year .month{
  position:relative;
  margin-bottom:1em
}

#timeline .year .month .month--header{
  font-size:1.4em;
  line-height:1.5em;
  margin:0;
  padding:0 0 0 81px;
  font-weight:normal;
  color: $grey-color
}

@media screen and (max-width:479px){
  #timeline .year .month .month--header{
    font-size:1.1em
  }

}

#timeline .year .month ul{
  list-style-type:none;
  padding:0 0 0 81px;
  margin:0 0 1em;
  font-size:1.4em;
  line-height:1.55em
}

#timeline .year .month ul:first-of-type::after{
  content:'';
  width:6px;
  height:6px;
  background:$grey-color;
  border:2px solid $background-color;
  -webkit-border-radius:50%;
  border-radius:50%;
  position:absolute;
  left:52px;
  top:5px;
  z-index:2
}

#timeline .year .month ul:last-child{
  margin-bottom: 0
}

#timeline .year .month .post {
  margin-bottom: 1em;
}
#timeline .year .month .post .day{
  position: relative;
  display: inline-block;
  color:  $grey-color-light;
  font-weight: 700;
  font-size: .6em;
}
#timeline .year .month .post .day:after{
  content: "";
  width: .6em;
  height: 1px;
  background:  $grey-color-light none repeat scroll 0% 0%;
  position: absolute;
  left: -1em;
  top: .6em;
  z-index: 2;
}
@media screen and (max-width:479px){
  #timeline .year .month ul{
    font-size:1.1em
  }

}
